@media (min-width: 576px) {
  .jumbotron {
    padding: 2rem 2rem;
  }
}

.profile {
  width: 10em;
  height: 10em;
  border: 5px solid rgba(255, 255, 2555, 0.7);
  border-radius: 50%;
  background-image: url('../../assets/images/nilroy.jpg');
  background-size: cover;
  margin: auto;
}

.site-header {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  background-color: white;
  box-shadow: 0px 3px 7px #bbbbbb;
  margin-bottom: 2em;

  padding: 1rem 1rem;

  h1,
  h2,
  h3 {
    font-weight: 300;
  }

  h2 {
    &.title {
      &:before {
        content: "<"
      }

      &:after {
        content: " />"
      }
    }
  }

  .title {
    font-family: monospace;
    color: #2069e0;
  }

  .cursive {
    font-family: 'Tangerine', cursive;
  }

  .gyan {
    font-size: 3em;
  }

  .intro {
    font-size: 1.2rem;
    font-weight: 300;

    .work, .edu{
      svg{
        color: orange;
      }
    }

    @media (max-width: 991.98px) {
      font-size: 1rem;
    }

    .contact {
      font-size: 1.75rem;
    }
  }

  .row {
    align-items: center;
  }
}