.contact {
    padding-top: 7px;
    text-align: center;

    .contact-links {
        display: flex;
        justify-content: space-around;
    }

    a,
    a:hover,
    a:visited,
    a:active {
        color: #ccc;
        svg {
            font-size: 36px;
        }

        &.linkedin {
            color: #0077B5;
        }

        &.github {
            color: #131418;
        }

        &.school {
            color: #159957;
        }

        &.instagram {
            color: #e4405f;
        }
        &.blog{
            color: #2069e0;
        }
        &.twitter {
            color: #55acee;
        }

        .fa-reddit {
            color: #ff5700;
        }

        &.hashnode {
            background-image: url('../../assets/images/hashnodeicon.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            width: 25px;
        }
    }
    .contact-links p {
        font-size: small;
    }
}