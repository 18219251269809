.footer {
    text-align: center;
    padding-top: 20px;
    font-weight: 300;
    color: #666666;
    font-size: 12px;
    margin-top: 2em;
    margin-bottom: 15px;
}

.support {
    padding-bottom: 7px;
    text-align: center;

    .support-links {
        display: flex;
        justify-content: center;

        a,
        a:hover,
        a:visited,
        a:active {
            color: #666666;
            text-decoration: none;
            padding: 0 10px;
        }
    }
}