
@keyframes fadeUp {
    0% {
       opacity: 0;
       transform: translateY(50px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }

.detail-pane{
    &.work{
        opacity: 0;
    }
    &.work .image {
        background-image: url('../../assets/images/undraw_dev_productivity_umsq.svg');
    }
    
    &.school{
        opacity: 0;
        animation-delay: 1s;
    }
    &.school .image {
        background-image: url('../../assets/images/undraw_road_to_knowledge_m8s0.svg');
    }

    .btn {
        margin-top: 1em;
    }

    padding: 1em 2em;
    box-shadow: 0px 4px 8px rgb(248, 249, 250);
    margin-bottom: 1em;
    background-color: white;

    animation-name: fadeUp;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;

    .image{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 10em;
    }

    .row{
        align-items: center;
    }

    text-align: center;
    .company{
        font-weight: 400;
        font-size: 1.7em;
    }
    .years{
        font-weight: 300;
        font-size: 0.8em;
    }
    .designation{
        font-style: italic;
        font-size: 1.2em;
        font-weight: 300;
    }
}

